import Lightbox from 'modules/gallery-lightbox';
import Gallery from 'modules/gallery';
import 'mypage/sticky-header';
import 'sbf-design-system-component/overlays/overlay';
import 'mypage/list';
import 'mypage/private-event-check';

import MyPageWebSockets from 'websockets/websocket.js';
MyPageWebSockets('participant');

const $mypagePics = $('#mypagePics');
const $mobilePics = $('#mobilePics');
const $mobileOnlyDiv = $('#mobileOnly');
const $eventInfoMobile = $mobileOnlyDiv.find('#eventInfo');

// toggle before and after photo on desktop view
$('#toggle-photo').on('change', function (e) {
  if ($(this).is(':checked')) {
    $mypagePics.addClass('before').removeClass('after');
  } else {
    $mypagePics.addClass('after').removeClass('before');
  }
});

// mobile toggle
$mobilePics.on('click', '.toggle-switch', function (e) {
  const $this = $(this);

  // if the toggle preview is the before photo
  if ($this.hasClass('before-photo')) {
    // remove toggle class
    $this.removeClass('toggle-switch'); // remove toggle class
    $mobilePics.find('.after-photo').addClass('toggle-switch'); // add toggle class to after photo
  } else {
    $this.removeClass('toggle-switch');
    $mobilePics.find('.before-photo').addClass('toggle-switch');
  }
});

Lightbox.lightboxID = '#mypage_photo_overlay';
Lightbox.photoID = '#mypage_photo_overlay_image';
Lightbox.setup();

Gallery.setup();

// Moving the event information from header down to the body on mobile
$eventInfoMobile.removeClass('desktop-only mypage-header__event-info')
  .addClass('event-info');

$(document).ready(() => {
  updatePhotoCSS();
});

$(window).on('resize', () => {
  updatePhotoCSS();
});

/**
 * This changes participant before and after photos CSS properties because square images need to be
 * handled differently than rectangular images on the mobile page version of the participant mypages.
 */
const updatePhotoCSS = () => {
  // Before photo.
  const $beforePhoto = $('.mobile--pics .before-photo img.participant_photo');
  const $hiddenBeforePhoto = $('.mobile--pics .hidden-before-photo');
  if ($hiddenBeforePhoto.length && $beforePhoto.length) {
    if ($hiddenBeforePhoto.width() === $hiddenBeforePhoto.height()) {
      $beforePhoto.css({ 'min-width': '100%', height: '100%' });
    } else {
      $beforePhoto.css({ 'min-width': 'auto', height: 'auto' });
    }
  }
  // After photo.
  const $afterPhoto = $('.mobile--pics .after-photo img.participant_photo');
  const $hiddenAfterPhoto = $('.mobile--pics .hidden-after-photo');
  if ($hiddenAfterPhoto.length && $afterPhoto.length) {
    if ($hiddenAfterPhoto.width() === $hiddenAfterPhoto.height()) {
      $afterPhoto.css({ 'min-width': '100%', height: '100%' });
    } else {
      $afterPhoto.css({ 'min-width': 'auto', height: 'auto' });
    }
  }
};
